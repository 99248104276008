import React from 'react';

function ContentShowcase() {
  return (
    <section id="content-showcase" className="content-section py-5 bg-dark text-white">
      <div className="container text-center">
        <h2 className="mb-5">What's Streaming Now</h2>
        <div id="streamingCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {[1, 2, 3, 4].map((item, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                <div className="card bg-secondary text-white mx-auto" style={{ maxWidth: '18rem' }}>
                  <img src={`/assets/images/show${item}.jpg`} className="card-img-top" alt={`Show ${item}`} />
                  <div className="card-body">
                    <h5 className="card-title">Artist Name - Date</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#streamingCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#streamingCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default ContentShowcase;
