import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);

function Login() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        user ? (
          <div>
            <h2>Welcome, {user.username}!</h2>
            <button onClick={signOut}>Sign Out</button>
          </div>
        ) : (
          <div className="container mt-5">
            <h2 className="text-center mb-4">Log In</h2>
            <div>
              {/* Username and Password fields are handled automatically by Authenticator */}
            </div>
          </div>
        )
      )}
    </Authenticator>
  );
}

export default Login;

