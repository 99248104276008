import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import ContentShowcase from './components/ContentShowcase';
import CTASection from './components/CTASection';
import FAQ from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import Shows from './pages/Shows';
import LiveShows from './pages/LiveShows'; 
import Subscriptions from './pages/Subscribe';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import './App.css';
import './assets/css/stylesheet.css';

Amplify.configure(awsconfig);

function ProtectedRoute({ element: Element }) {
  return (
    <Authenticator>
      {({ user }) => (user ? <Element /> : <Login />)}
    </Authenticator>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={
              <>
                <Hero />
                <ContentShowcase />
                <CTASection />
              </>
            } />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/Subscribe" element={<Subscriptions />} />
            

            {/* Protected Routes */}
            <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route path="/ondemand" element={<ProtectedRoute element={Shows} />} />
            <Route path="/liveshows" element={<ProtectedRoute element={LiveShows} />} />
            
          </Routes>
          <Footer />
        </div>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
