import React from 'react';

function TermsOfUse() {
  return (
    <div className="container my-5 text-start">
         <h1 className="mb-4">Terms of Use</h1>
         <p><strong>Effective Date:</strong> August 19, 2024</p>

         <h2 className="mt-4">1. Acceptance of Terms</h2>
         <p>By accessing or using the Vercee website and services, you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree with any part of these terms, you must not use our services.</p>

         <h2 className="mt-4">2. Changes to Terms</h2>
         <p>We reserve the right to modify these Terms of Use at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services following any changes constitutes your acceptance of the new terms.</p>

         <h2 className="mt-4">3. Use of Services</h2>
         <p>Vercee provides an online platform for streaming and accessing live and on-demand performances. You agree to use our services only for lawful purposes and in accordance with these Terms of Use.</p>
         <ul>
            <li><strong>Account Registration:</strong> You may be required to create an account to access certain features of our services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
            <li><strong>Prohibited Conduct:</strong> You agree not to engage in any of the following prohibited activities:
                <ul>
                    <li>Using our services for any illegal or unauthorized purpose.</li>
                    <li>Attempting to interfere with or disrupt the functionality of our services.</li>
                    <li>Misrepresenting your identity or affiliation with any person or entity.</li>
                    <li>Uploading, posting, or transmitting any content that infringes on the rights of others or violates any applicable law.</li>
                </ul>
             </li>
         </ul>

         <h2 className="mt-4">4. Intellectual Property</h2>
         <p>All content available on Vercee, including text, graphics, logos, videos, and software, is the property of Vercee or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from our services without our express written permission.</p>

         <h2 className="mt-4">5. User-Generated Content</h2>
         <p>You may have the opportunity to post or submit content to Vercee. By doing so, you grant Vercee a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your content in connection with the services. You represent and warrant that you have the rights to any content you submit and that it does not infringe on the rights of others.</p>

         <h2 className="mt-4">6. Disclaimers and Limitation of Liability</h2>
         <ul>
             <li><strong>Commitment to Quality:</strong> At Vercee, we are committed to providing you with the best possible service experience. We continuously strive to ensure our platform is reliable, secure, and user-friendly. While we aim to maintain uninterrupted access and smooth operation, there may be times when issues arise that are outside of our control. In such cases, we will do our best to resolve any problems as quickly as possible.</li>
             <li><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Vercee shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of our services. Our goal is to minimize any disruption to your experience, and we appreciate your understanding in the event that something does not go as planned.</li>
         </ul>

         <h2 className="mt-4">7. Termination</h2>
         <p>We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms of Use or is harmful to other users, us, or third parties.</p>

         <h2 className="mt-4">8. Governing Law</h2>
         <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of Washington, without regard to its conflict of law principles. You agree to submit to the personal and exclusive jurisdiction of the courts located within Clark County, Washington.</p>

         <h2 className="mt-4">9. Contact Us</h2>
         <p>If you have any questions about these Terms of Use, please contact us at: <a href="mailto:support@vercee.com">support@vercee.com</a>.</p>
    </div>
  );
}

export default TermsOfUse;
