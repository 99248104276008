import React, { useEffect } from 'react';
import '../assets/css/stylesheet.css'; 
import { Card, Button, Row, Col } from 'react-bootstrap';
import image1 from '../assets/images/herophoto1.jpg';

const Subscriptions = () => {
  useEffect(() => {
    console.log("Subscribe component mounted");
  }, []);

  return (
    <div className="container subscription-page">
      <Row className="subscription-options text-center">
        <Col md={6}>
          <Card className="mb-4 subscription-card">
            <Card.Body>
              <Card.Title>Basic Subscription</Card.Title>
              <Card.Text>$19.99/month</Card.Text>
              <ul className="list-unstyled">
                <li>Access to all live shows</li>
                <li>Access to all recordings</li>
                <li>Standard quality streaming</li>
              </ul>
              <Button variant="primary">Subscribe Now</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4 subscription-card">
            <Card.Body>
              <Card.Title>Premium Subscription</Card.Title>
              <Card.Text>$27.99/month</Card.Text>
              <ul className="list-unstyled">
                <li>Everything in Basic</li>
                <li>Exclusive content</li>
                <li>HD streaming</li>
                <li>Priority support</li>
              </ul>
              <Button variant="primary">Subscribe Now</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="mt-5">
        <h2 className="text-center">Upcoming Pay-Per-View Events</h2>
        <div className="d-flex justify-content-center">
          <Card className="ppv-card">
            <Card.Img variant="top" src={image1} />
            <Card.Body className="text-center">
              <Card.Title>Event Title 1</Card.Title>
              <Button variant="primary">Purchase PPV</Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;

