import React from 'react';
import heroImage from '../assets/images/herophoto1.jpg';

function Hero() {
  return (
    <section id="hero" className="hero-section text-center text-white d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${heroImage})`, height: '80vh', backgroundSize: 'cover' }}>
      <div className="container">
        <h1 className="display-4 font-weight-bold">Experience Live Music Like Never Before</h1>
        <p className="lead">Bringing unique, high-quality live performances to your screen.</p>
        <a href="/SignUp" className="btn btn-primary btn-lg">Get Started</a>
      </div>
    </section>
  );
}

export default Hero;
