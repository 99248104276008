import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import headerLogo from '../assets/images/headerlogo.png';

function Header() {
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);

  return (
    <header id="header" className="bg-dark text-white py-3">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <a className="navbar-brand" href="/">
            <img src={headerLogo} alt="Vercee Logo" height="50" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item header-btn">
                <a href="/" className="nav-link text-white">Home</a>
              </li>
              <li className="nav-item header-btn">
                <a href="/LiveShows" className="nav-link text-white">Live Shows</a>
              </li>
              <li className="nav-item header-btn">
                <a href="OnDemand" className="nav-link text-white">Watch Now</a>
              </li>
              {user ? (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-white" href="/profile" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {user.username}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="/profile">Profile</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><button className="dropdown-item" onClick={signOut}>Sign Out</button></li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item header-btn">
                  <a href="/Login" className="nav-link text-white">Log In</a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;



