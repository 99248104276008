import React from 'react';

function CTASection() {
  return (
    <section id="cta-section" className="cta-section py-5 text-center cta-background text-white">
      <div className="container">
        <h2 className="mb-4">Subscribe</h2>
        <p className="lead">Subscribe today to access exclusive content</p>
        <a href="/Subscribe" className="btn btn-light btn-lg">Sign Up Now</a>
      </div>
    </section>
  );
}

export default CTASection;
