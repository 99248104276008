import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);

function SignUp() {
  return (
    <Authenticator
      initialState="signUp"
      formFields={{
        signUp: {
          username: {
            label: 'Username',
            placeholder: 'Enter your username',
            required: true,
          },
          email: {
            label: 'Email',
            placeholder: 'Enter your email',
            required: true,
          },
          password: {
            label: 'Password',
            placeholder: 'Enter your password',
            required: true,
          },
          confirm_password: {
            label: 'Confirm Password',
            placeholder: 'Confirm your password',
            required: true,
          },
        },
      }}
    >
      {({ signOut, user }) => (
        user ? (
          <div>
            <h2>Welcome, {user.username}!</h2>
            <button onClick={signOut}>Sign Out</button>
          </div>
        ) : (
          <div className="container mt-5">
            <h2 className="text-center mb-4">Sign Up</h2>
            <div>
              {/* Username, Email, and Password fields are handled automatically by Authenticator */}
            </div>
          </div>
        )
      )}
    </Authenticator>
  );
}

export default SignUp;


