import React, { useEffect, useState } from 'react';
import { createVideoPlayer } from 'amazon-ivs-player';
import { useAuthenticator } from '@aws-amplify/ui-react';
import fallbackImage from '../assets/images/silhouettelights.jpg';
import { useNavigate } from 'react-router-dom';

const LiveStreamPage = ({ playbackUrl, fallbackContent }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [player, setPlayer] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState(false); // Placeholder for payment status

  useEffect(() => {
    // Fetch user payment status here if the user is logged in
    if (user) {
      // Replace with actual payment status check
      const fetchPaymentStatus = async () => {
        // Simulate payment status
        const paidStatus = true; // Fetch from your database or API
        setIsPaid(paidStatus);
      };
      fetchPaymentStatus();
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    if (user && !isPaid) {
      navigate('/subscribe'); // Redirect to sales page if the user hasn't paid
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://player.live-video.net/1.5.0/amazon-ivs-player.min.js';
    script.async = true;
    script.onload = () => {
      if (window.IVSPlayer.isPlayerSupported) {
        const newPlayer = createVideoPlayer({
          playbackUrl,
        });

        newPlayer.attachHTMLVideoElement(document.getElementById('ivs-player'));

        newPlayer.addEventListener('playing', () => {
          setIsLive(true);
          setError(null);
        });

        newPlayer.addEventListener('ended', () => {
          setIsLive(false);
        });

        newPlayer.addEventListener('error', (err) => {
          setError(err);
          setIsLive(false);
        });

        newPlayer.play();
        setPlayer(newPlayer);
      } else {
        setError(new Error('IVS Player is not supported'));
      }
    };
    script.onerror = () => {
      setError(new Error('Failed to load IVS Player script'));
    };
    document.body.appendChild(script);

    return () => {
      if (player) {
        player.removeEventListener('playing');
        player.removeEventListener('ended');
        player.removeEventListener('error');
        player.delete();
      }
      document.body.removeChild(script);
    };
  }, [playbackUrl, player, user, isPaid, navigate]); // Added `navigate` to the dependency array

  if (!user || !isPaid) {
    return null; // You can replace this with a loading spinner or message if desired
  }

  return (
    <div className="container">
      {error ? (
        <div className="alert alert-danger" role="alert">
          Error: {error.message}
        </div>
      ) : isLive ? (
        <div className="embed-responsive embed-responsive-16by9">
          <video id="ivs-player" controls className="embed-responsive-item" />
        </div>
      ) : (
        <div className="text-center">
          <img src={fallbackImage} alt="Fallback for no live stream" className="img-fluid" />
          <div className="overlay-content">
            <div className="bg-dark text-white p-4 rounded">
              <p>Come back later, we aren't live.</p>
              <button className="btn btn-primary mt-3">Get Tickets</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveStreamPage;


