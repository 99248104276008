import React from 'react';

function Footer() {
  return (
    <footer id="footer" className="bg-dark text-white py-4">
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <ul className="list-inline">
              <li className="list-inline-item"><a href="/faq" className="text-white">FAQ</a></li>
              <li className="list-inline-item"><a href="/termsofuse" className="text-white">Terms of Use</a></li>
              <li className="list-inline-item"><a href="/privacypolicy" className="text-white">Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <p>&copy; 2024 Vercee LLC. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
