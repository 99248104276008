import React from 'react';
import faqHeroImage from '../assets/images/smokeyguitar.jpg';

function FAQ() {
  return (
    <div className="container my-5">
      <section className="position-relative text-center text-white">
      <img src={faqHeroImage} className="img-fluid w-100" alt="Concert lights" style={{ objectFit: 'cover', height: '60vh' }} />
        <div className="position-absolute top-50 start-50 translate-middle">
          <h1 className="display-3 fw-bold text-white">Frequently Asked Questions</h1>
        </div>
      </section>

      <div className="accordion" id="faqAccordion">
        {/* Accordion Items */}
        {[
          { id: 1, question: 'What is Vercee?', answer: 'Vercee is a virtual concert platform that allows you to experience live music events from the comfort of your home. We bring you high-quality performances from your favorite artists, streamed directly to your device.' },
          { id: 2, question: 'How does Vercee work?', answer: 'Vercee partners with artists to create unique live performances that are streamed online. You can purchase a ticket for a Pay-Per-View event or subscribe to access a library of on-demand performances.' },
          { id: 3, question: 'What are the benefits of performing on Vercee?', answer: 'Vercee offers artists the ability to reach a global audience without the logistical challenges of touring. We handle the production, streaming, and even offer revenue-sharing opportunities from ticket sales and merchandise.' },
          { id: 4, question: 'How can I watch a concert on Vercee?', answer: 'To watch a concert, simply browse our event listings, select the concert you’re interested in, and purchase a ticket. You can also subscribe to get access to our entire library of live and on-demand concerts.' },
          { id: 5, question: 'What makes Vercee different from other streaming platforms?', answer: 'Vercee is specifically designed for live music performances, offering artists creative freedom to design unique shows. We handle all production aspects, so artists can focus on delivering an outstanding performance. For fans, Vercee provides interactive features, such as live chat and exclusive content, that enhance the overall experience beyond just watching a stream.' },
          { id: 6, question: 'Why should I use Vercee for virtual concerts?', answer: 'Vercee offers a unique, immersive concert experience that combines the best elements of live music, Broadway performances, and music videos. Unlike other platforms, Vercee is dedicated solely to creating high-quality, artist-driven events that are tailored to virtual audiences, making each performance a truly memorable experience.' },
        ].map(({ id, question, answer }) => (
          <div className="accordion-item bg-dark border border-secondary" key={id}>
            <h2 className="accordion-header" id={`heading${id}`}>
              <button className="accordion-button collapsed bg-secondary text-white" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`}>
                {question}
              </button>
            </h2>
            <div id={`collapse${id}`} className="accordion-collapse collapse" aria-labelledby={`heading${id}`} data-bs-parent="#faqAccordion">
              <div className="accordion-body bg-dark text-light">
                {answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
