import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

function Profile() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <div className="container mt-5">
      <h2 className="text-center">Profile</h2>
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Welcome, {user.username}!</h5>
          <p className="card-text">Email: {user.attributes.email}</p>
          <button className="btn btn-primary" onClick={signOut}>
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;

