import React, { useState, useEffect } from 'react';
import image1 from '../assets/images/herophoto1.jpg';

const Shows = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { id: 1, artist: 'Artist Name 1', date: 'Show Date 1', image: image1 },
    { id: 2, artist: 'Artist Name 2', date: 'Show Date 2', image: image1 },
    { id: 3, artist: 'Artist Name 3', date: 'Show Date 3', image: image1 },
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const handleNext = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    };
  
    const interval = setInterval(handleNext, 7000);
    return () => clearInterval(interval);
  }, [items.length]);
  

  return (
    <div className="shows-page">
      <div className="carousel-section custom-carousel">
        {items.map((item, index) => (
          <div
            key={item.id}
            className={`custom-carousel-item ${index === activeIndex ? 'active' : ''} ${
              index === (activeIndex - 1 + items.length) % items.length ? 'prev' : ''
            } ${
              index === (activeIndex + 1) % items.length ? 'next' : ''
            }`}
            onClick={() => handleClick(index)}
          >
            <img className="custom-carousel-image" src={item.image} alt={`Slide ${item.id}`} />
            <div className="custom-carousel-caption">
              <h3>{item.artist}</h3>
              <p>{item.date}</p>
              <button className="btn">Preorder Tickets</button>
            </div>
          </div>
        ))}
      </div>

      <div className="previous-shows-grid mt-5">
        <h2 className="previous-shows-title">Previous Shows</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="card custom-card">
              <img src={image1} className="card-img-top custom-card-image" alt="Show 1" />
              <div className="card-body custom-card-body">
                <h5 className="card-title custom-card-title">Artist Name 1</h5>
                <p className="text-light custom-card-text">Show Title 1</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card custom-card">
              <img src={image1} className="card-img-top custom-card-image" alt="Show 2" />
              <div className="card-body custom-card-body">
                <h5 className="card-title custom-card-title">Artist Name 2</h5>
                <p className="text-light custom-card-text">Show Title 2</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card custom-card">
              <img src={image1} className="card-img-top custom-card-image" alt="Show 3" />
              <div className="card-body custom-card-body">
                <h5 className="card-title custom-card-title">Artist Name 3</h5>
                <p className="text-light custom-card-text">Show Title 3</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shows;